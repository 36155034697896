@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-Thin.9ba0e1f5.woff2") format("woff2"), url("Inter-Thin.824d496a.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-ThinItalic.13536ea1.woff2") format("woff2"), url("Inter-ThinItalic.11fac0ad.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLight.cacafd21.woff2") format("woff2"), url("Inter-ExtraLight.12e36f82.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLightItalic.5b7c0533.woff2") format("woff2"), url("Inter-ExtraLightItalic.d49c6779.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-Light.10797873.woff2") format("woff2"), url("Inter-Light.c35fe515.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-LightItalic.2f309154.woff2") format("woff2"), url("Inter-LightItalic.911de0dc.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.ed77b881.woff2") format("woff2"), url("Inter-Regular.ca6858d7.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.17850d8c.woff2") format("woff2"), url("Inter-Italic.1fc1e013.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.54f1243d.woff2") format("woff2"), url("Inter-Medium.5d032a23.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-MediumItalic.ebe4cf3b.woff2") format("woff2"), url("Inter-MediumItalic.17b7958a.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.dd034768.woff2") format("woff2"), url("Inter-SemiBold.cc1168df.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBoldItalic.726b7e22.woff2") format("woff2"), url("Inter-SemiBoldItalic.36e0049c.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.af5441a3.woff2") format("woff2"), url("Inter-Bold.419e8c71.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-BoldItalic.31bfb722.woff2") format("woff2"), url("Inter-BoldItalic.2531fb17.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBold.c2a0acf6.woff2") format("woff2"), url("Inter-ExtraBold.b6c97f29.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBoldItalic.89dd2891.woff2") format("woff2"), url("Inter-ExtraBoldItalic.ba0db31b.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-Black.20ec9d84.woff2") format("woff2"), url("Inter-Black.089e8f16.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-BlackItalic.c978f255.woff2") format("woff2"), url("Inter-BlackItalic.93c72837.woff") format("woff");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("Inter-roman.var.910be013.woff2") format("woff2");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("Inter-italic.var.7aa56ba0.woff2") format("woff2");
}

@font-face {
  font-family: Inter var experimental;
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 10deg;
  src: url("Inter.var.e60f01e0.woff2") format("woff2");
}
/*# sourceMappingURL=index.60b90307.css.map */
